<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-2 white pt-3 pb-2">
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="4" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 75) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
        <v-col cols="3" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 py-0 ma-auto" />
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-2"
            :class="{
                white: !hover && !isActive,
                'concrete-solid': hover || isActive,
                'px-2': $vuetify.breakpoint.mdAndDown
            }"
        >
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                <span
                    v-if="article.announcement?.is_in_author_process"
                    class="text-caption"
                >
                    Submitted on
                </span>
                <text-truncated
                    :text="formatDate(article.publish_time)"
                    :tooltip="formatDateTime(Number(article.publish_time))"
                />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto py-2">
                <status-chip :status="ampType" />
                <status-chip :status="article.status_string" class="ml-1" />
                <br />
                <status-chip
                    v-if="isPremium"
                    status="Premium Distribution"
                    color="transparent"
                    icon-only
                    class="ml-1"
                >
                    <template #icon>
                        <v-icon small color="turquoise">gem</v-icon>
                    </template>
                </status-chip>
                <status-chip
                    v-if="isFinancial"
                    status="Financial Distribution"
                    color="transparent"
                    icon-only
                    class="ml-1"
                >
                    <template #icon>
                        <v-icon small color="purple lighten-1">
                            fa-comment-dollar
                        </v-icon>
                    </template>
                </status-chip>
                <status-chip
                    v-if="isMsn"
                    status="MSN Distribution"
                    color="transparent"
                    icon-only
                    class="ml-1"
                >
                    <template #icon>
                        <v-icon small color="msn-blue">
                            $vuetify.icons.msn
                        </v-icon>
                    </template>
                </status-chip>
                <status-chip
                    v-if="isGuestPostPremium"
                    status="B.L.O.W. Distribution"
                    color="transparent"
                    icon-only
                    class="ml-1"
                >
                    <template #icon>
                        <v-icon small color="msn-blue">
                            fire-flame-curved
                        </v-icon>
                    </template>
                </status-chip>
            </v-col>
            <v-col cols="4" class="pl-2 pr-0 ma-auto">
                <text-truncated :text="article.headline" />
                <span class="text-caption">
                    (Hoth Reference ID {{ article.id }})
                </span>
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                <div
                    v-if="article.announcement?.category"
                    class="text-caption primary--text"
                >
                    {{ article.announcement.category.name }}
                </div>
            </v-col>
            <v-col cols="3" class="pl-2 pr-0 ma-auto">
                <text-truncated :text="article.user_message" />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <article-actions :article="article" @open="markRowActive" />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { formatDate, formatDateTime, randomBetween } from '@/utils/helpers';

import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';

import ArticleActions from './ArticleActions.vue';

import type { PressRelease } from '@/types/PressRelease';
import { PrEventStatus } from '@/types/PrEvent';

const ArticleRowProps = Vue.extend({
    name: 'ArticleRow',
    props: {
        article: {
            type: Object as PropType<Partial<PressRelease>>,
            default(): PressRelease | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        TextTruncated,
        StatusChip,
        ArticleActions
    }
})
export default class ArticleRow extends ArticleRowProps {
    isActive = false;

    get ampType() {
        if (
            this.article.requested_author_time ||
            this.article.status === PrEventStatus.AwaitingPayment
        ) {
            return 'DFY';
        }

        return 'DIY';
    }

    get isPremium() {
        return Boolean(
            this.article.announcement?.is_premium || this.article.is_premium
        );
    }

    get isFinancial() {
        return this.article.announcement?.is_financial;
    }

    get isMsn() {
        return Boolean(this.article.announcement?.is_msn);
    }

    get isGuestPostPremium() {
        return Boolean(this.article.announcement?.is_guest_post_premium);
    }

    formatDate = formatDate;
    formatDateTime = formatDateTime;
    randomBetween = randomBetween;

    markRowActive(isActive: boolean) {
        this.isActive = isActive;
    }
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';
</style>
