<template>
    <v-row v-if="loading" class="ma-0">
        <v-col cols="1" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="60%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="70%" height="20" />
        </v-col>
        <v-col cols="4" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="25%" height="20" />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="50%" height="20" />
        </v-col>
        <v-col cols="3" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="25%" height="20" />
        </v-col>
        <v-col cols="1" />
    </v-row>
    <v-row v-else class="caption text-uppercase ma-0">
        <v-col cols="1" class="pl-2 pr-0">Publish Date</v-col>
        <v-col cols="2" class="pl-2 pr-0">Status</v-col>
        <v-col cols="4" class="pl-2 pr-0">Headline</v-col>
        <v-col cols="1" class="pl-2 pr-0">Category</v-col>
        <v-col cols="3" class="pl-2 pr-0">Message</v-col>
        <v-col cols="1" />
    </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ArticlesListHeaderProps = Vue.extend({
    name: 'ArticlesListHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class ArticlesListHeader extends ArticlesListHeaderProps {}
</script>
