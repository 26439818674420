import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"transition":"slide-y-transition","close-on-click":true,"close-on-content-click":true,"offset-y":true,"left":""},on:{"input":_vm.open},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","plain":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("ellipsis-v")])],1)]}}])},[_c(VList,[_c(VListItem,{key:"review",attrs:{"href":_vm.reviewLink,"target":"_blank"}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("eye")])],1),_c(VListItemTitle,[_vm._v("Review")])],1),_c(VListItem,{key:"profile",attrs:{"href":_vm.userProfileLink,"target":"_blank"}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("user")])],1),_c(VListItemTitle,[_vm._v("User Profile")])],1),_c(VListItem,{key:"report",attrs:{"href":_vm.reportLink,"target":"_blank","disabled":!_vm.hasReport}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,{attrs:{"small":"","disabled":!_vm.hasReport}},[_vm._v("chart-pie")])],1),_c(VListItemTitle,[_vm._v("See Report")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }