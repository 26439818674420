<template>
    <v-menu
        transition="slide-y-transition"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-y="true"
        left
        @input="open"
    >
        <template #activator="{ on, attrs }">
            <v-btn fab plain v-bind="attrs" v-on="on">
                <v-icon>ellipsis-v</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item key="review" :href="reviewLink" target="_blank">
                <v-list-item-icon class="mr-2">
                    <v-icon small>eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Review</v-list-item-title>
            </v-list-item>
            <v-list-item key="profile" :href="userProfileLink" target="_blank">
                <v-list-item-icon class="mr-2">
                    <v-icon small>user</v-icon>
                </v-list-item-icon>
                <v-list-item-title>User Profile</v-list-item-title>
            </v-list-item>
            <v-list-item
                key="report"
                :href="reportLink"
                target="_blank"
                :disabled="!hasReport"
            >
                <v-list-item-icon class="mr-2">
                    <v-icon small :disabled="!hasReport">chart-pie</v-icon>
                </v-list-item-icon>
                <v-list-item-title>See Report</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { PressRelease } from '@/types/PressRelease';
import { PrEventStatus } from '@/types/PrEvent';

const ArticleActionsProps = Vue.extend({
    name: 'ArticleActions',
    props: {
        article: {
            type: Object as PropType<Partial<PressRelease>>,
            default(): PressRelease | null {
                return null;
            }
        }
    }
});

@Component
export default class ArticleActions extends ArticleActionsProps {
    get reviewLink() {
        return `/app/announcements/review/${this.article.announcement_id}`;
    }

    get reportLink() {
        return `/app/announcements/report/${this.article.announcement_id}`;
    }

    get userProfileLink() {
        return `/users/edit/${this.article.user_id}`;
    }

    get hasReport() {
        return (
            typeof this.article.status !== 'undefined' &&
            [PrEventStatus.Pending, PrEventStatus.Live].includes(
                this.article.status
            )
        );
    }

    open(isOpen: boolean) {
        this.$emit('open', isOpen);
    }
}
</script>
