<template>
    <v-container>
        <v-row class="mt-2 mb-4 px-3">
            <div class="text-h6 text-md-h5 font-weight-bold">
                Search News Articles
            </div>
        </v-row>
        <v-container class="elevation-1 rounded">
            <v-row class="white px-2 pt-2 pb-0 rounded">
                <v-col class="pa-0" cols="12" xl="7">
                    <v-tabs v-model="by">
                        <v-tab href="#user">By User</v-tab>
                        <v-tab href="#headline">By Amp ID / Headline</v-tab>
                        <v-tab-item value="headline">
                            <a-form v-slot="{ valid }" :warn-dirty="false">
                                <v-container>
                                    <v-row>
                                        <v-col class="pb-0 pr-2">
                                            <a-text-input
                                                v-model="term"
                                                label="Subset of Headline Text or Amp ID"
                                                rules="required|min:3"
                                                clearable
                                                :disabled="isBusy"
                                                @keypress.enter="search"
                                            />
                                        </v-col>
                                        <v-col cols="auto" class="pb-0 pt-5">
                                            <v-btn
                                                color="primary"
                                                large
                                                :disabled="isBusy || !valid"
                                                :loading="isBusy"
                                                @click="search"
                                            >
                                                Search
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </a-form>
                        </v-tab-item>
                        <v-tab-item value="user">
                            <a-form v-slot="{ valid }" :warn-dirty="false">
                                <v-container>
                                    <v-row>
                                        <v-col class="pb-0 pr-2">
                                            <a-text-input
                                                v-model="email"
                                                label="User Email"
                                                rules="required|email"
                                                clearable
                                                :disabled="isBusy"
                                                @keypress.enter="search"
                                            />
                                        </v-col>
                                        <v-col cols="auto" class="pb-0 pt-5">
                                            <v-btn
                                                color="primary"
                                                large
                                                :disabled="isBusy || !valid"
                                                :loading="isBusy"
                                                @click="search"
                                            >
                                                Search
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </a-form>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>

        <articles-list
            v-if="showList"
            :articles="articles"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0"
        />

        <paging
            v-if="showList"
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            class="main-background px-0"
        />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { ListPaged } from '@/mixins';
import { Watch } from '@/utils/decorators';

import { Paging } from '@/components/Paging';

import { ArticlesList } from '@/components/ArticlesList';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { PressRelease } from '@/types/PressRelease';

@Component({
    components: {
        AForm,
        ATextInput,
        ArticlesList,
        Paging
    }
})
export default class Search extends mixins(ListPaged) {
    endpoint = '/press_releases/admin';

    articles: PressRelease[] = [];

    term = '';
    email = '';
    doneSearching = false;

    by = 'user';

    get queryParams() {
        return {
            limit: 10,
            page: 1,
            by: this.by,
            term: this.term,
            email: this.email
        };
    }

    get showList() {
        return (this.term || this.email) && this.doneSearching;
    }

    get isBusy() {
        return this.isLoading || this.isUpdating;
    }

    @Watch('term')
    onTermUpdated() {
        this.doneSearching = false;
    }

    @Watch('reseller')
    onResellerUpdated() {
        this.doneSearching = false;
    }

    onMounted() {
        this.setLoading(false);
    }

    onData(data: { length: number; pressReleases: PressRelease[] }) {
        const { pressReleases, length } = data;

        this.articles = pressReleases;

        this.total = length;

        this.doneSearching = true;
    }

    search() {
        if (this.$route.query.page) {
            // resetting will auto-execute search
            this.resetPager();
        } else {
            return this.load();
        }
    }

    resetPager() {
        this.$router.push({
            query: {
                ...this.$route.query,
                page: void 0 // every searching should reset paging, if any
            }
        });
    }
}
</script>
