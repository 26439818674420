<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 2 : 0">
            <v-list-item three-line class="pr-0">
                <v-list-item-content v-if="loading">
                    <v-skeleton-loader type="article" />
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-list-item-title>
                        {{ article.headline }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption py-2">
                        {{ published }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <status-chip :status="ampType" />
                        <status-chip
                            :status="article.status_string"
                            class="ml-1"
                        />
                        <br />
                        <status-chip
                            v-if="isPremium"
                            status="Premium Distribution"
                            color="transparent"
                            icon-only
                            class="ml-1"
                        >
                            <template #icon>
                                <v-icon small color="turquoise">gem</v-icon>
                            </template>
                        </status-chip>
                        <status-chip
                            v-if="isFinancial"
                            status="Financial Distribution"
                            color="transparent"
                            icon-only
                            class="ml-1"
                        >
                            <template #icon>
                                <v-icon small color="purple lighten-1">
                                    fa-comment-dollar
                                </v-icon>
                            </template>
                        </status-chip>
                        <status-chip
                            v-if="isMsn"
                            status="MSN Distribution"
                            color="transparent"
                            icon-only
                            class="ml-1"
                        >
                            <template #icon>
                                <v-icon small color="msn-blue">
                                    $vuetify.icons.msn
                                </v-icon>
                            </template>
                        </status-chip>
                        <status-chip
                            v-if="isGuestPostPremium"
                            status="B.L.O.W. Distribution"
                            color="transparent"
                            icon-only
                            class="ml-1"
                        >
                            <template #icon>
                                <v-icon small color="msn-blue">
                                    fire-flame-curved
                                </v-icon>
                            </template>
                        </status-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <article-actions :article="article" />
                </v-list-item-action>
            </v-list-item>
        </v-card>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { formatDateTime } from '@/utils/helpers';

import { StatusChip } from '@/components/StatusChip';

import ArticleActions from './ArticleActions.vue';

import type { PressRelease } from '@/types/PressRelease';
import { PrEventStatus } from '@/types/PrEvent';

const ArticleCardOptions = Vue.extend({
    name: 'ArticleCard',
    props: {
        article: {
            type: Object as PropType<Partial<PressRelease>>,
            default(): PressRelease | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        StatusChip,
        ArticleActions
    }
})
export default class ArticleCard extends ArticleCardOptions {
    get published() {
        return formatDateTime(Number(this.article.publish_time));
    }

    get ampType() {
        if (
            this.article.requested_author_time ||
            this.article.status === PrEventStatus.AwaitingPayment
        ) {
            return 'DFY';
        }

        return 'DIY';
    }

    get isPremium() {
        return Boolean(
            this.article.announcement?.is_premium || this.article.is_premium
        );
    }

    get isFinancial() {
        return this.article.announcement?.is_financial;
    }

    get isMsn() {
        return Boolean(this.article.announcement?.is_msn);
    }

    get isGuestPostPremium() {
        return Boolean(this.article.announcement?.is_guest_post_premium);
    }

    formatDateTime = formatDateTime;
}
</script>
