<template>
    <v-container v-if="isBusy && $vuetify.breakpoint.mdAndUp">
        <articles-list-header
            v-if="$vuetify.breakpoint.mdAndUp"
            :loading="loading"
        />
        <article-row
            v-for="article in articles.length || 10"
            :key="'article-row-' + article"
            loading
        />
    </v-container>
    <v-container v-else-if="isBusy">
        <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="card-heading, list-item-avatar-three-line"
            class="mb-6"
        />
    </v-container>
    <v-container v-else-if="hasData && $vuetify.breakpoint.mdAndUp">
        <articles-list-header />
        <article-row
            v-for="(article, index) in articles"
            :key="'article-row-' + article.id + '-' + index"
            :article="article"
        />
    </v-container>
    <v-container v-else-if="hasData" class="px-0">
        <article-card
            v-for="(article, index) in articles"
            :key="'article-card-' + article.id + '-' + index"
            :article="article"
            class="mb-2"
            flat
            style="cursor: pointer"
        />
    </v-container>
    <a-message-bar v-else>No News Articles found</a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AMessageBar } from '@/components/AMessageBar';

import ArticlesListHeader from './ArticlesListHeader.vue';
import ArticleRow from './ArticleRow.vue';
import ArticleCard from './ArticleCard.vue';

import type { PressRelease } from '@/types/PressRelease';

const ArticlesListProps = Vue.extend({
    name: 'ArticlesList',
    props: {
        articles: {
            type: Array as PropType<Partial<PressRelease>[]>,
            default(): PressRelease[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ArticlesListHeader,
        ArticleRow,
        ArticleCard,
        AMessageBar
    }
})
export default class ArticlesList extends ArticlesListProps {
    article: Partial<PressRelease> | null = null;

    get hasData() {
        return Boolean(this.articles.length);
    }

    get isBusy() {
        return this.loading || this.updating;
    }
}
</script>
